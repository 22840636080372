import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { colors } from '../../../../colors.js'
import {
  borderRadius,
  ButtonLink,
  spacing,
  useLanguage,
  useScreenVariant,
  useSkin,
  useTranslate,
  type ScreenVariant,
} from '../../../../index.js'
import { PhoneNumber, Text, TextContainer } from '../../../atoms/index.js'
import { OrusFaceAvatar } from '../../universal-subscription/index.js'

type OnContactClick = () => void

export type HelpContactDetails = {
  email: string
  phone: string | undefined
  whatsappLink?: string
}

export type HelpBlockProps = {
  forceScreenVariant?: ScreenVariant
  onContactClick: OnContactClick
  className?: string
  helpContactDetails: HelpContactDetails
  variant?: 'branded' | 'neutral'
}

export const HelpBlock = memo<HelpBlockProps>(function HelpBlock(props) {
  const translate = useTranslate()
  const language = useLanguage()
  const screenVariant = useScreenVariant()
  const skin = useSkin()
  const {
    forceScreenVariant,
    helpContactDetails: { phone, whatsappLink, email },
    variant = 'branded',
  } = props

  const actualScreenVariant = forceScreenVariant ?? screenVariant

  return (
    <StyledHelpBlock actualScreenVariant={actualScreenVariant}>
      <StyledHelpBlockLeft>
        {actualScreenVariant === 'mobile' ? (
          <OrusFaceAvatar
            css={css`
              margin-bottom: ${spacing[40]};
            `}
            variant="round"
            size="60"
          />
        ) : (
          <></>
        )}
        <TextContainer color={skin.helpBlockTextColor}>
          <Text variant="subtitle1">{translate('subscription_need_help_title')}</Text>
          <Text
            variant="body2"
            css={css`
              margin-top: ${spacing[30]};
            `}
          >
            {variant === 'branded'
              ? translate('subscription_need_help_explanation')
              : translate('subscription_need_help_explanation_broker')}
          </Text>
        </TextContainer>

        <StyledHelpBlockLeftButtons actualScreenVariant={actualScreenVariant}>
          {phone ? (
            <ButtonLink
              variant="primary"
              size={actualScreenVariant === 'mobile' ? 'large' : 'medium'}
              fullWidth={actualScreenVariant === 'mobile'}
              icon="phone-regular"
              avatarPosition="left"
              to={`tel:${phone}`}
              trackingId="call_button"
            >
              <PhoneNumber>{phone.replace(/^\+33 ?/, '0')}</PhoneNumber>
            </ButtonLink>
          ) : null}

          {whatsappLink && variant === 'branded' ? (
            <ButtonLink
              variant="tertiary"
              size={actualScreenVariant === 'mobile' ? 'large' : 'medium'}
              fullWidth={actualScreenVariant === 'mobile'}
              avatarPosition="left"
              icon="whatsapp-brands"
              target="_blank"
              to={whatsappLink}
            >
              <Text variant="button" color={skin.helpBlockTextColor}>
                WhatsApp
              </Text>
            </ButtonLink>
          ) : null}

          {email && variant === 'neutral' ? (
            <ButtonLink
              variant="tertiary"
              size={actualScreenVariant === 'mobile' ? 'large' : 'medium'}
              fullWidth={actualScreenVariant === 'mobile'}
              avatarPosition="left"
              icon="envelope-regular"
              target="_blank"
              to={`email:${email}`}
            >
              {translate('send_an_email')}
            </ButtonLink>
          ) : null}
        </StyledHelpBlockLeftButtons>
      </StyledHelpBlockLeft>
      {actualScreenVariant === 'desktop' && variant === 'branded' ? (
        <StyledHelpBlockRight>
          <StyledHelpBlockRightImage src={skin.quoteHelpBannerImageUrl[language]} alt="" />
        </StyledHelpBlockRight>
      ) : (
        <></>
      )}
    </StyledHelpBlock>
  )
})

const StyledHelpBlock = styled.div<{ actualScreenVariant: ScreenVariant }>`
  background: ${colors.sky.gradient};
  padding: ${({ actualScreenVariant }) =>
    actualScreenVariant === 'mobile' ? spacing[70] : `${spacing[70]} 0 0 ${spacing[70]}`};
  border-radius: ${borderRadius[30]};
  display: flex;
  gap: ${({ actualScreenVariant }) => (actualScreenVariant === 'mobile' ? spacing[70] : spacing[70])};
  container-type: inline-size;
`

const StyledHelpBlockLeft = styled.div`
  flex: 1;
`

const StyledHelpBlockLeftButtons = styled.div<{ actualScreenVariant: ScreenVariant }>`
  display: flex;
  gap: ${spacing[30]};
  flex-direction: ${({ actualScreenVariant }) => (actualScreenVariant === 'desktop' ? 'row' : 'column')};
  padding-bottom: ${({ actualScreenVariant }) => (actualScreenVariant === 'mobile' ? '0px' : `${spacing[70]}`)};
  margin-top: ${spacing[70]};
`

const StyledHelpBlockRight = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  container-type: size;
  border-bottom-right-radius: 12px;

  @container (max-width: 580px) {
    display: none;
  }
`

const StyledHelpBlockRightImage = styled.img`
  width: 258px;
  position: absolute;

  @container (max-width: 258px) {
    align-self: flex-start;
  }
`
