import { css } from '@emotion/react'
import { memo, useMemo } from 'react'
import { colors } from '../../../../colors.js'
import { colorTokens } from '../../../../foundation/color-tokens.js'
import { spacing } from '../../../../foundation/spacing-tokens.js'
import type { ScreenVariant } from '../../../../hooks/use-screen-variant.js'
import { useTranslate } from '../../../../localization/language-context.js'
import { Text } from '../../../atoms/index.js'
import { Block } from '../block/index.js'
import { GuaranteesList } from '../guarantees-list/index.js'
import { OptionGuaranteeList } from '../guarantees-option-list/index.js'
import { HelpBlock, type HelpContactDetails } from '../help-block/index.js'
import type { Guarantee, Option } from '../types.js'

export type GuaranteesSection = {
  name: string
  id: string
  guarantees: Guarantee[]
}

export type GuaranteesTableProps = {
  variant: ScreenVariant

  guaranteesSection: GuaranteesSection[]
  includedGuaranteesCount: number
  options: Option[]
  perOptionSubOptions: Record<string, Option[]>

  onOptionAddOrRemoveClick?: (option: Option, type: 'add' | 'remove') => void
  onGuaranteeClick: (guarantee: Guarantee) => void
  onInfoOptionClick: (option: Option) => void
  onContactClick: () => void

  className?: string
  quotePageType?: 'static' | 'dynamic'
  isMuta: boolean

  helpContactDetails: HelpContactDetails
}

export const GuaranteesTable = memo<GuaranteesTableProps>(function GuaranteesTable(props) {
  const translate = useTranslate()
  const {
    variant,
    guaranteesSection,
    includedGuaranteesCount,
    options,
    perOptionSubOptions,
    onOptionAddOrRemoveClick,
    onGuaranteeClick,
    onContactClick,
    onInfoOptionClick,
    className,
    quotePageType,
    isMuta,
    helpContactDetails,
  } = props

  const tableType = quotePageType ?? 'dynamic'
  const displayableOptions = tableType === 'dynamic' ? options : options.filter((opt) => opt.selected)

  const optionsList = useMemo(
    () => (
      <OptionGuaranteeList
        variant={variant}
        options={displayableOptions}
        onOptionAddOrRemoveClick={onOptionAddOrRemoveClick}
        onInfoOptionClick={onInfoOptionClick}
        css={css`
          margin-top: ${variant === 'mobile' ? spacing[60] : spacing[70]};
        `}
        quotePageType={tableType}
        isMuta={isMuta}
      />
    ),
    [variant, displayableOptions, onOptionAddOrRemoveClick, onInfoOptionClick, tableType, isMuta],
  )

  const helpBlock = useMemo(
    () => (
      <HelpBlock
        forceScreenVariant={variant}
        onContactClick={onContactClick}
        helpContactDetails={helpContactDetails}
        css={css`
          margin-top: ${spacing[70]};
          margin-bottom: ${spacing[70]};
        `}
      />
    ),
    [variant, onContactClick, helpContactDetails],
  )

  const optionsTitle = useMemo(
    () => (
      <>
        {displayableOptions.length > 1 ? (
          <Text variant="subtitle1" color={colors.blue[900]}>
            Les {displayableOptions.length} options{' '}
            <span
              css={css`
                color: ${colors.blue[900]};
              `}
            >
              {tableType === 'dynamic' ? 'supplémentaires' : 'incluses'}
            </span>
          </Text>
        ) : (
          <Text variant="subtitle1">Option incluse</Text>
        )}
      </>
    ),
    [displayableOptions.length, tableType],
  )

  return (
    <div
      className={className}
      css={css`
        flex: ${variant === 'mobile' || variant === 'backoffice' ? '1' : undefined};
      `}
    >
      <Block
        css={css`
          padding: ${variant === 'mobile' || variant === 'backoffice' ? 0 : undefined};
          box-shadow: ${variant === 'mobile' || variant === 'backoffice' ? 'none' : undefined};
          flex: ${variant === 'mobile' || variant === 'backoffice' ? '1' : undefined};
          border: 1px solid ${colorTokens['color-stroke-base']};
        `}
        variant={variant}
        title={<GuaranteeTableTitle includedGuaranteesCount={includedGuaranteesCount} />}
      >
        {guaranteesSection.map((section) => (
          <div
            id={section.id}
            key={section.id}
            css={css`
              margin-top: ${variant === 'mobile' || variant === 'backoffice' ? spacing[60] : spacing[70]};
            `}
          >
            {guaranteesSection.length > 1 ? <Text variant="subtitle2">{section.name}</Text> : <></>}

            <GuaranteesList
              variant={variant}
              guarantees={section.guarantees}
              onGuaranteeClick={onGuaranteeClick}
              css={css`
                margin-top: ${variant === 'mobile' ? 0 : spacing[30]};
              `}
              quotePageType={tableType}
              isMuta={isMuta}
            />
          </div>
        ))}
        {Object.entries(perOptionSubOptions).map(([optionName, subOptions]) => (
          <div
            id={optionName}
            key={optionName}
            css={css`
              margin-top: ${variant === 'mobile' ? spacing[60] : spacing[70]};
            `}
          >
            <Text variant="subtitle1">{translate('option_guarantees_section_title', { optionName })}</Text>

            <OptionGuaranteeList
              variant={variant}
              options={subOptions}
              onOptionAddOrRemoveClick={onOptionAddOrRemoveClick}
              onInfoOptionClick={onInfoOptionClick}
              css={css`
                margin-top: ${variant === 'mobile' ? spacing[60] : spacing[70]};
              `}
              quotePageType={tableType}
              isMuta={isMuta}
            />
          </div>
        ))}

        {variant === 'desktop' ? (
          <div
            css={css`
              margin-top: ${spacing[70]};
            `}
          >
            {' '}
            {helpBlock}
            <>
              {displayableOptions.length > 0 ? (
                <>
                  <Text
                    variant="subtitle1"
                    css={css`
                      margin-top: ${spacing[70]};
                    `}
                  >
                    {optionsTitle}
                  </Text>
                  {optionsList}
                </>
              ) : (
                <></>
              )}
            </>
          </div>
        ) : (
          <></>
        )}
      </Block>

      {variant === 'mobile' ? (
        <>
          <div
            css={css`
              margin-top: ${spacing[70]};
            `}
          >
            {helpBlock}
          </div>
          <>
            {displayableOptions.length > 0 ? (
              <Block
                variant={variant}
                title={optionsTitle}
                css={css`
                  margin-top: ${spacing[70]};
                `}
              >
                {optionsList}
              </Block>
            ) : (
              <></>
            )}
          </>
        </>
      ) : (
        <></>
      )}
    </div>
  )
})

type GuaranteeTableTitleProps = { includedGuaranteesCount: number }
const GuaranteeTableTitle = memo<GuaranteeTableTitleProps>(function GuaranteeTableTitle({ includedGuaranteesCount }) {
  const translate = useTranslate()
  return includedGuaranteesCount === 1 ? (
    <Text variant="subtitle1" color={colors.blue[900]}>
      {translate('the_guarantee_included')}
    </Text>
  ) : (
    <Text variant="subtitle1" color={colors.blue[900]}>
      {translate('the_x_guarantees_included', { count: includedGuaranteesCount })}
    </Text>
  )
})
